import Cookies from "js-cookie";
import { createContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
// create context
export const SidebarContext = createContext();
export const SidebarProvider = ({ children }) => {
  const resultsPerPage = 20;
  const searchRef = useRef("");
  const invoiceRef = useRef("");
  const [limitData, setLimitData] = useState(20);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [productCategory, setProductCategory] = useState(null);
  const [isDrawerOpen1, setIsDrawerOpen1] = useState(false);
  const [isBulkDrawerOpen, setIsBulkDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [type, setType] = useState("");
  const [refreshShopInfo, setRefreshShopInfo] = useState(false);
  const [lang, setLang] = useState("fr");
  const [time, setTime] = useState("");
  const [plateforme, setPlateforme] = useState("");
  const [language, setLanguage] = useState(lang);
  const [image, setImage] = useState([]);
  const [sortedField, setSortedField] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [zone, setZone] = useState("");
  const [status, setStatus] = useState("");
  const [category, setCategory] = useState(null);
  const [demande, setDemande] = useState(null)
  const [category1, setCategory1] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [windowDimension, setWindowDimension] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);
  const [navBar, setNavBar] = useState(true);
  const { i18n } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const [checked, setChecked] = useState("");
  const [selected, setSelected] = useState("");
  const [permissions, setPermissions] = useState([]);
  const closeSidebar = () => setIsSidebarOpen(false);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const closeDrawer = () => {
    setLanguage(lang);
    setIsDrawerOpen(false);
    setImage([]);
    setChecked("");
    setDemande(null)
    setProductCategory("");
  };
  const toggleDrawer = () => {
    setLanguage(lang);
    setIsDrawerOpen(!isDrawerOpen);
    setImage([]);
    setChecked("");
    setProductCategory("");
  };

  const showDemande = (demande) => {
    setIsDrawerOpen(true);
    setDemande(demande)
  } 


  const closeDrawer1 = () => {
    setLanguage(lang);
    setIsDrawerOpen1(false);
    setImage([]);
    setChecked("");
    setProductCategory("");
  };
  const toggleDrawer1 = () => {
    setLanguage(lang);
    setIsDrawerOpen1(!isDrawerOpen1);
    setImage([]);
    setChecked("");
    setProductCategory("");
  };

  const closeBulkDrawer = () => setIsBulkDrawerOpen(false);
  const toggleBulkDrawer = () => setIsBulkDrawerOpen(!isBulkDrawerOpen);

  const closeModal = () => setIsModalOpen(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleLanguageChange = (lang) => {
    Cookies.set("i18next", lang);
    i18n.changeLanguage(lang);
    setLang(lang);
  };

  const handleChangePage = (p) => {
    setCurrentPage(p);
  };

  const handleSubmitForAll = (e) => {
    e.preventDefault();
    if (!searchRef?.current?.value) return setSearchText(null);
    setSearchText(searchRef?.current?.value);
    setCategory(null);
  };

  useEffect(() => {
    const lang = Cookies.get("i18next");
    if (lang === "en-US" || lang === "en-GB") {
      setLang("en");
    } else if (lang === "fr-FR" || lang === "fr-FR") {
      setLang("fr");
    } else {
      setLang(Cookies.get("i18next"));
    }
  }, [windowDimension]);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        toggleSidebar,
        closeSidebar,
        isDrawerOpen,
        toggleDrawer,
        closeDrawer,
        setIsDrawerOpen,
        closeBulkDrawer,
        isBulkDrawerOpen,
        toggleBulkDrawer,
        isModalOpen,
        toggleModal,
        closeModal,
        isUpdate,
        setIsUpdate,
        lang,
        setLang,
        handleLanguageChange,
        currentPage,
        setCurrentPage,
        handleChangePage,
        searchText,
        setSearchText,
        plateforme,
        setPlateforme,
        category,
        setCategory,
        searchRef,
        handleSubmitForAll,
        status,
        setStatus,
        refreshShopInfo,
        toggleDrawer1,
        closeDrawer1,
        isDrawerOpen1,
        setRefreshShopInfo,
        zone,
        setZone,
        time,
        setTime,
        sortedField,
        demande,
        showDemande,
        setSortedField,
        image,
        setImage,
        language,
        setLanguage,
        resultsPerPage,
        limitData,
        setLimitData,
        windowDimension,
        modalOpen,
        checked,
        productCategory,
        setProductCategory,
        setChecked,
        setModalOpen,
        setSelected,
        permissions,
        setPermissions,
        selected,
        category1,
        setCategory1,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        loading,
        setLoading,
        type,
        setType,
        invoice,
        setInvoice,
        invoiceRef,
        setNavBar,
        navBar,
        tabIndex,
        setTabIndex,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
