import React, { createContext, useReducer } from "react";

export const AdminContext = createContext();

const initialState = {
  adminInfo: localStorage.getItem("adminInfo")
    ? JSON.parse(localStorage.getItem("adminInfo") || "{}")
    : null,
  userInfo: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo") || "{}")
    : null,

  shopInfo: localStorage.getItem("shopInfo")
    ? JSON.parse(localStorage.getItem("shopInfo") || "{}")
    : null,
};

function reducer(state, action) {
  switch (action.type) {
    case "USER_LOGIN":
      return {
        ...state,
        adminInfo: action.payload,
      };

    case "USER_INFO":
      return {
        ...state,
        userInfo: action.payload,
      };
    case "SHOP_INFO":
      return {
        ...state,
        shopInfo: action.payload,
      };
    case "USER_LOGOUT":
      return {
        ...state,
        adminInfo: null,
        shopInfo: null,
        userInfo: null,
      };

    default:
      return state;
  }
}

export const AdminProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
  );
};
