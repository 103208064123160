import React, { useContext } from "react";
import { Modal } from "antd";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { NotificationContext } from "context/NotificationContext";

const NitificationModal = ({ visible, children, size }) => {
  const { showModal } = useContext(NotificationContext);
  return (
    <Modal
      modalRender={() => (children)}
      open={showModal}
    />
  );
};

export default NitificationModal;
