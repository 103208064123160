import React, { createContext, useEffect, useState, useContext } from "react";
import { AdminContext } from "./AdminContext";
export const NotificationContext = createContext();


export const NotificationProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const {
    state: { userInfo },
  } = useContext(AdminContext);
  const toggleNotification = () => setShowModal(!showModal);

  useEffect(() => {
    if (!("Notification" in window)) {
        console.log("Browser does not support desktop notification");
      } else {
        console.log("Browser support desktop notification");
        Notification.requestPermission();
      }
  }, [])

  const showNotification = (data) => {
    var options = {
        body: data?.message,
        icon: 'https://cdn-icons-png.flaticon.com/512/565/565422.png',
        dir: 'ltr',
      };
  
      let notification = new Notification(data?.title, options);
    //new Notification('Hello World')
  }










  Pusher.logToConsole = true;
  var pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
    cluster: "eu",
  });
  var channel = pusher.subscribe("wafri-web-notifications");
  channel.bind("order-notification", function (data) {
    if(data?.user?.id === userInfo.id){
        toggleNotification()
        showNotification(data?.body)
        //alert("Ok ni yeye no ana pasha pata iyi notification");
    }
    
  });

  return (
    <NotificationContext.Provider
      value={{
        showModal,
        toggleNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
