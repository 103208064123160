import React, { lazy, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "./utils/toast";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import PrivateRoute from "./components/login/PrivateRoute";
import NitificationModal from "components/modal/NitificationModal";
import { NotificationContext } from "context/NotificationContext";
import { AiOutlineCloseCircle } from "react-icons/ai";
import PublicRoutes from "components/login/PublicRoutes";
const Layout = lazy(() => import("./layout/Layout"));
const Login = lazy(() => import("./pages/Login"));
const SignUp = lazy(() => import("./pages/SignUp"));
const ForgetPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const Validation = lazy(() => import("./pages/Validation"));
const Otp = lazy(() => import("./pages/Otp"));

const App = () => {
  const {toggleNotification} = useContext(NotificationContext)
  return (
    <>
      <ToastContainer />
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/signup" component={SignUp} />
          <Route path="/forgot-password" component={ForgetPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/validation" component={Validation} />
          <Route path="/validation-code" component={Otp} />

          <PrivateRoute>
            {" "}
            <Route path="/" component={Layout} />
          </PrivateRoute>

          {/* <Redirect exact from="/" to="/login" /> */}
        </Switch>
      </Router>
      <NitificationModal>
        <div className="border-none shadow-lg relative p-5 rounded w-full pointer-events-auto bg-white dark:bg-slate-800 rounded-none">
          <div className="flex justify-end  relative">
            <button onClick={toggleNotification}  className="cursor-pointer">
              <AiOutlineCloseCircle size={20} color="red" />
            </button>
          </div>
          <div class="container z-9999 py-10 px-10 mx-0 min-w-full flex flex-col items-center">
            <p className="font-bold text-lg text-center">
              You have a new order. Please check your order list.
            </p>
            <button
              type="button"
              class="text-white bg-green-800 hover:bg-green-500 focus:ring-4 focus:ring-blue-300 font-bold rounded-full text-sm px-20 py-3 mb-2 dark:bg-green-800 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-blue-800"
            >
              Let me check
            </button>
          </div>
        </div>
      </NitificationModal>
    </>
  );
};

export default App;
